<template>
     <div class=" industry body">
          <div class="container headcarousel">
               <div>
                    <div class="columns reverse-columns">
                         <div class="column ">
                              <div class="image" data-aos="fade-right" data-aos-duration="1000">
                                   <div
                                        class="square img"
                                        :style="{ 'background-image': `url(${require('@/assets/images/industries/hospitality.jpg')})` }"
                                   ></div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="text" data-aos="fade-left" data-aos-duration="1000">
                                   <h1>Hospitality <span class="uppertext">02</span></h1>

                                   <div class="mt-30">
                                        <p>
                                             CTL collaborates with you for an authenticated and customised technological solution that elevates your
                                             brand experience.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="projects">
               <div class="container">
                    <div class="  section-title">Hospitality Projects</div>

                    <div class="columns" style="margin-bottom:50px;">
                         <div class="column">
                              <div class="section-header" style="margin-bottom:0;">
                                   Technology designed meticulously to be seamless and discreet.
                              </div>
                         </div>

                         <div class=" hoopernavigation">
                              <button class="prev" @click="hooperPrev()" :class="{ visible: hooper.isPrevBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(1115.74 145.803) rotate(180)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                              <button class="next" @click="hooperNext()" :class="{ visible: hooper.isNextBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(-1073 -126.299)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                         </div>
                    </div>
               </div>
               <div class="  overflow-carousel-container">
                    <div class="overflow-carousel">
                         <hooper :settings="hooper_settings" class="slider" ref="hooper" @slide="hooperSlide">
                              <slide v-for="(item, index) in projects" :key="index" class="">
                                   <div class="slide-container">
                                        <router-link :to="{ name: item.link }" class="">
                                             <div class="image-container">
                                                  <!-- <img :src="require(`@/assets/images/${item.image}`)" /> -->
                                                  <div
                                                       class="rect img"
                                                       v-if="item.image"
                                                       :style="{ 'background-image': `url(${require('@/assets/images/projects/' + item.image)})` }"
                                                  ></div>

                                                  <div
                                                       class="rect img"
                                                       v-else
                                                       :style="{ 'background-image': `url(${require('@/assets/images/placeholder.png')})` }"
                                                  ></div>
                                             </div>
                                             <div class="mt-30 line-left  " style="padding-left:20px;min-height:91px;">
                                                  <div class="date">{{ item.location }}</div>
                                                  <p class="mt-10 large " v-html="item.name"></p>
                                             </div>
                                        </router-link>
                                   </div>
                              </slide>
                         </hooper>
                    </div>
               </div>
          </div>

          <div class="content">
               <div class="container">
                    <div class="  section-title">Hospitality Services</div>
                    <div class="  section-header">Designed to upgrade Brand experience.</div>

                    <div class="columns">
                         <div class="column ">
                              <div class="line-top ">
                                   <!-- <div class="section-title ">
                                        Benefits of ITeS System
                                   </div> -->
                                   <p>
                                        The hospitality industry is one of the driving forces of the global economy. The widespread adoption of new
                                        technologies in this industry over recent years has fundamentally reshaped the way in which brands are
                                        elevating their experience.<br />IOT enabled platforms on the connected devices and Artificial intelligence on
                                        the electronic surveillance systems are changing the landscape of hospitality service as more and more smart
                                        devices are entering the markets. This calls for a major technological overhaul in the hospitality industry.
                                        CTL has smarter solutions to overcome fundamental challenges and institute a future proof solution for the
                                        hotel industry. Most of the guests today carry multiple devices such as phones, tablets, laptops, etc. The
                                        internal Wifi coverage, bandwidth and connectivity has to be planned considering the same.
                                   </p>

                                   <p>
                                        Each room requires multiple data connectivity to provide services like , IPTV, IP Phones, Room management ,
                                        Wifi AP, etc. Today even the Mini Bar consumption is also monitored remotely and integrated with the PMS
                                        Software.
                                   </p>

                                   <p>
                                        The central connectivity must be planned with the adequate bandwidth and should have no redundancy. Most chain
                                        of hotels have a common policy - they will have an independent department for IT facility management, Guest
                                        management and Security management. It is always better to provide three physical networks to control and
                                        manage the network in a better way.
                                   </p>

                                   <p>
                                        The guest networks need to be classified under security risk since it is always accessed by the outside guest.
                                        Considering this fact a proper unified threat management and firewall system must be implemented.
                                   </p>
                                   <p>
                                        IoT in hospitality offers increased integration with most of the connected devices. The lights, air
                                        conditioning, refrigeration, laundry, kitchen equipment, gym, etc all such devices are going to be on IoT
                                        platforms. A well planned connectivity design and implementation is required at the foundational level stage.
                                        Since post commencement of operations, downtime will be detrimental.
                                   </p>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="line-top ">
                                   <div class="section-title ">
                                        Specific Services
                                   </div>
                                   <p>
                                        CTL has over 25 years of experience in providing solutions for the hospitality industry. We provided the
                                        following well designed solutions based on the project requirement.
                                   </p>

                                   <p>
                                        Wired and Wireless Data <br />
                                        Switching and Routing <br />
                                        Firewalls and Servers <br />
                                        IP Telephony <br />
                                        IP Surveillance <br />
                                        Access control systems <br />
                                        Building Management Systems <br />
                                        Data Centre and Command control solutions <br />
                                        IoT and Room Management Systems <br />
                                        Audio and Video <br />
                                        Digital Signage <br />
                                        IP TV Solutions <br />
                                        Parking Management <br />
                                        Addressable smoke detection systems <br />
                                        DAS (Distributed Antenna Systems) <br />
                                   </p>
                              </div>
                              <div class="line-top pt-6 mt-6">
                                   <p>
                                        The physical security threat is another challenge in the hotel industry. A proper integrated IP Surveillance
                                        solution must be designed and executed covering all threat areas.
                                   </p>

                                   <p>
                                        Service block and guest area must be separated with access controlled doors and this needs to be integrated
                                        with IP cameras. Basic AI (Artificial Intelligence) can help the system provide advance notification and
                                        alerts in the command control center.
                                   </p>
                                   <p>Vehicle entry control systems like Boom Barriers, bollards , ANPR can be planned at the entry gates.</p>
                                   <p>
                                        Hotel softwares and hardwares very crucial since they operate 24 hours without any downtime. A proper Data
                                        Center design can avoid any accidental shutdown of IT active components.
                                   </p>
                                   <p>
                                        Most of the large properties are now combined with the convention center for conferences and marriages. A well
                                        designed AV system must be implemented across the property.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";

     export default {
          data() {
               return {
                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },

                    hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 3.6,
                                   itemsToSlide: 1,
                              },
                         },
                    },

                    sliderOptions: {},
                    selected: 0,

                    projects: [
                         {
                              name: "Marriott Hotel Courtyard",
                              location: "Kochi-Kerala",
                              text:
                                   "ICT Solution <br> Electronic Security <br>  IP Surveillance <br>  ITeS  Solution <br>  BMS Solutions <br>  Access control Systems ",
                              image: "hospitality/Mariott_hotel_courtyard/1.jpg",
                         },
                         {
                              name: "City Marriott Hotel Kochi",
                              location: "Kochi-Kerala",
                              text: `ICT Solution <br>Electronic Security <br>IP Surveillance <br>ITeS  Solution<br>Access control Systems `,
                              image: "hospitality/City_marriot_hotel_kochi/1.jpg",
                         },

                         {
                              name: "Hotel Olive Down Town",
                              location: "Kochi-Kerala",
                              text: `ICT Solution
                                                       <br>Electronic Security
                                                       <br>IP Surveillance
                                                       <br>TeS  Solution
                                                       <br>Access Control Systems
                                                       <br>Audio Video Solutions`,
                              image: "hospitality/Hotel_olive_down_town/1.jpg",
                         },

                         {
                              name: "Grand Hyatt ",
                              location: "Kochi-Kerala",

                              text: `Electronic Security  Systems
                                                        <br>IP Surveillance
                                                        <br>Access Control Systems
                                                        <br>Entry Control Systems
                                                        <br>Data Center Build
                                                        <br>AV infra
                                                        <br>Structured Cabling`,
                              image: "hospitality/Grand_Hyatt/1.jpg",
                         },

                         {
                              name: "Hyatt Regency and Lulu Convention Center",
                              location: "Kochi-Kerala",
                              text: `Data Centre`,

                              image: "hospitality/Hyatt_regency_Lulu_convention_centre/1.jpeg",
                         },
                         {
                              name: "TAJ Hotel Wayanad <span>(Banasura Hotels)</span>",
                              location: "Wayanad-Kerala",

                              text: `GPON Solution
                                                   <br>IP Surveillance
                                                   <br>ITeS  Solution
                                                   <br>Structured Cabling `,

                              image: "hospitality/taj_hotel_wayanad/1.jpg",
                         },
                         {
                              name: "Hotel Residency",
                              location: "Mumbai",
                              text: "ICT Solution  <br>ITeS  Solution  ",
                              image: "hospitality/Hotel_diplomat/1.jpeg",
                         },
                    ],
               };
          },
          components: {
               Hooper,
               Slide,
          },
          methods: {
               hooperSlide(data) {
                    this.hooper.slideNo = data.currentSlide;

                    if (this.hooper.slideNo > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper.slideNo == this.projects.length - 1) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper.slideNo > 3.3) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    }
               },
               hooperPrev() {
                    this.$refs.hooper.slidePrev();
               },
               hooperNext() {
                    this.$refs.hooper.slideNext();
               },
          },

          mounted() {},
     };
</script>

<style lang="scss" scoped></style>
